import { useCommon } from '@/components/aggrid/common/useCommon';
import GridSelectEditorNeo from '@/components/aggrid/cell_editors/GridSelectEditorNeo';
import GridTextEditor from '@/components/aggrid/cell_editors/GridTextEditor';
import DatePicker from '@/components/aggrid/cell_editors/GridDateEditor';
import { useUtilities } from  '@/composables/useUtilities';
import ReportCellRenderer from '@/components/aggrid/cell_renderers/ReportCellRenderer';
import GateInOutReceiptCellRenderer from '@/components/aggrid/cell_renderers/GateInOutReceiptCellRenderer';
import GridIconRenderer from '@/components/aggrid/cell_renderers/GridIconRenderer';
import GridSelectWithIconFilter from '@/components/aggrid/filters_new/GridSelectWithIconFilter';
import GridSelectFloatingFilter from '@/components/aggrid/floating_filters_new/GridSelectFloatingFilter';
import GridDateFilter from '@/components/aggrid/filters_new/GridDateFilter';
import GridTextFloatingFilter from '@/components/aggrid/floating_filters_new/GridTextFloatingFilter.vue';
import GridTextFilter from '@/components/aggrid/filters_new/GridTextFilter';
import { createRenderer } from '@vue/runtime-core';
import StockListValidator from '@/validations/StockListValidator';
import { computed } from 'vue';



export function useStockListDefinition(store, dayJs) {


    
    //COMPOSABLES
    const { utilities } = useUtilities(store);



    const getStockListDefinition = () => {

        const { common } = useCommon();

        const toolTipValueGetter = (params) => {
            return params.value
        };

        return [
            ...common.columnDefs(),
            ...[

            {   headerName: 'New', field: 'new',
                hide: true
            },
            {
                headerName: 'Last Gate In Task Status', field: 'last_gate_in_yamax_task_status',
                hide: true, cellEditor: 'agTextCellEditor',
            },
            {
                headerName: 'Last Gate Out Task Status', field: 'last_gate_out_yamax_task_status',
                hide: true, cellEditor: 'agTextCellEditor',
            },
            {
                headerName: 'Id', field: 'id', hide: true,
                editable: false,
                filterParams: {
                    allowedCharPattern: '\\d\\-\\,',
                    numberParser: text => {
                        return text == null ? null : parseFloat(text.replace(',', '.'));
                    }
                }
            },
            {
                headerName: 'SC Flow ID', field: 'id', hide: true,
                cellEditor: 'agTextCellEditor',

            },
            {
                headerName: 'Status', field: 'stock_container_status_id',
                editable: false,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilter: true,
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Document Tag', 
                field: 'document_tag_id',
                //minWidth: 170,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: StockListValidator.isRequired('document_tag_id') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilter: true,
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Unit Number', field: 'unit_number',
                cellEditor: GridTextEditor,
                cellEditorParams: {
                    pattern: "^[a-z|A-Z]{4}[0-9]{7}$",
                    emptyStringIsNull: true,
                    onUpdate: (oldValue, newValue) => {
                        if (newValue.length == oldValue.length - 1 && newValue == oldValue.substring(0, oldValue.length - 1)) {
                            //It's a character delete so don't do anything
                            return newValue;
                        } else {
                            if (newValue && newValue.length == 10) {
                                return newValue + utilities.generateUnitNumberCheckNumber(newValue);
                            } else if (newValue && newValue.length == 11) {
                                //Next two lines need to work when we have a way of notifying the user that the check number might be wrong
                                let newValue10 = newValue.substring(0, 10);
                                let newValue10Checknumber = utilities.generateUnitNumberCheckNumber(newValue10);
                                if (newValue.substring(10) != newValue10Checknumber) {
                                    store.addNotificationMessage("Unit number <b>" + newValue + "</b> has wrong check number", 'warning');
                                }
                                return newValue;
                            } else {
                                return newValue;
                            }
                        }
                    }
                },
                //Filter
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {headerName: '', field: 'blocked',
                width: 100,
                headerComponentParams: {template: '<span><i large class="mdi-lock-reset mdi" style="font-size: 1.5rem"></em>'},
                tooltipComponentParams: {
                    type: 'simple'
                },
                headerTooltip: 'Block/Unblock',
                minWidth: 30,
                editable: true,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    onlyIcons: true,
                    valueList: [
                        { id: '1', name: 'Blocked', icon: 'mdi-lock-outline', color: 'red darken-1' },
                        { id: '0', name: 'Unblocked', icon: 'mdi-lock-open-variant-outline', color: 'green lighten-1' },
                        { id: '', name: 'Unblocked', icon: 'mdi-lock-open-variant-outline', color: 'green lighten-1', noSelect: true },
                    ]
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                cellStyle: { 'text-align': 'left' },
                cellRenderer: GridIconRenderer,
                cellRendererParams: {
                    'valueToUse': 'value',
                    'valueList': {
                        '1': { icon: 'mdi-lock-outline', color: 'red darken-1', message: 'Blocked' },
                        '0': { icon: 'mdi-lock-open-variant-outline', color: 'green lighten-1', message: 'Unblocked' },
                        '': { icon: 'mdi-lock-open-variant-outline', color: 'green lighten-1', message: 'Unblocked' },
                    }
                },
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: {
                    options: [
                        {
                            id: '0',
                            name: '',
                            icon: 'mdi-lock-open-variant-outline',
                            color: 'success'
                        },
                        {
                            id: '5',
                            name: '',
                            icon: 'mdi-lock-outline',
                            color: 'error'
                        }
                    ]
                },
            },
            {
                headerName: 'Report', field: 'report',
                width: 100,
                editable: false,
                cellRenderer: ReportCellRenderer,
                cellRendererParams: {},
                //Filter
                filter: 'agTextColumnFilter',
                //Here we need to access somehow the props.params.data.report__report.id
                //For some reason the textFormatter and textMAtcher are not called. Maybe we need a ag-grid update
                /*filterParams: {
                    textFormatter: (string) => {
                        console.log('Formatter');
                        console.log(string);

                    },
                    textMatcher: (filterOption, value, filterText) => {
                        console.log('Matcher');
                        console.log(filterOption);
                        console.log(value);
                        console.log(filterText);
                        if (filterText == null) {
                            return false;
                        }
                        return value.indexOf(filterText) >= 0;
                    }
                },*/
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                headerName: 'Inspection Condition', field: 'damaged', hide: hideContainerStatus,
                editable: false,                
                cellEditor: 'agTextCellEditor',
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: { options: [
                    { id: 'Intact', name: 'Intact' },
                    { id: 'Damaged', name: 'Damaged' },
                    { id: 'Instant Repair', name: 'Instant Repair' }
                ] },
                //Floating
                floatingFilter: true,
                floatingFilterComponent: GridSelectFloatingFilter,
                //floatingFilterComponent: GridSetFloatingFilter,
                floatingFilterComponentParams: {},
            },
            {
                headerName: 'Inspection Criteria', 
                field: 'inspection_criteria',
                //Editor
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: StockListValidator.isRequired('inspection_criteria') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Receipts', field: 'receipts',
                width: 90,
                editable: false,
                cellRenderer: GateInOutReceiptCellRenderer,
                cellRendererParams: {
                },
                //Filter
                filter: false,
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                headerName: 'ISO Code', field: 'iso_code', hide: false,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: StockListValidator.isRequired('booking_category_id') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilter: true,
                floatingFilterComponent: GridSelectFloatingFilter,
                //floatingFilterComponent: GridSetFloatingFilter,
                floatingFilterComponentParams: {},
            },
            {
                headerName: 'Construction Year', field: 'construction_year',
                hide: false,
                editable: false,
                cellEditor: 'agTextCellEditor',
                width: 160,
                //Filter
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                headerName: 'Facility/Depot', field: 'depot_id', hide: false,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { 
                    valueList: [],
                    default: StockListValidator.isRequired('depot_id') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Shipping Line/Operator', field: 'matched_shipping_line', hide: false,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { 
                    valueList: [],
                    default: StockListValidator.isRequired('matched_shipping_line') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Leasing Company', field: 'matched_leasing_company', hide: false,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { 
                    valueList: [],
                    default: StockListValidator.isRequired('matched_leasing_company') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Gate-In Date', field: 'gate_in_date', hide: false,
                editable: function(params){
                    return true;
                },                
                width: 150,
                cellEditor: DatePicker,
                cellEditorParams: {
                    'format': 'datetime',
                    config: {
                        maxDate: dayJs().toDate()
                    }
                },
                comparator: utilities.dateComparator,
                valueFormatter: function (params) {
                    return params.value ? utilities.formatDateTime(params.value) : "";
                },
                //Filter
                filter: GridDateFilter,
                 filterParams: {format:'date'},
                //Floating
                //floatingFilterComponent: GridDateFloatingFilter,
                floatingFilterComponentParams: {}                
            },
            {
                headerName: 'Days in Depot', 
                field: 'days_in',
                hide: false,
                editable: false,
                width: 100,
                valueFormatter: function (params) {
                    if (params.data.gate_in_date) {
                        return dayJs().diff(dayJs(params.data.gate_in_date), 'days');
                    } else {
                        return '';
                    }
                },
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                headerName: 'Return Reference', field: 'turn_in_reference', hide: false,
                editable: false,
                cellEditor: 'agTextCellEditor',
                //Filter
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                },
                //Tooltip
                tooltipComponentParams: {
                    type: 'text',
                    source: 'url',
                    sourceData: function (data) {
                        return data.booking_number ? "reference/"+data.return__reference.id +"/stockContainer" : null;
                    },
                    extractData: function(response){
                        return {
                            stockContainers: response.data.models.stockContainers,
                            validity: response.data.reference.validity
                        };
                    },
                    columns: [
                        {headerName: 'Containers:', field: 'stockContainers', valueFormatter: function (params) {
                                return params.value.filter(function (item) {
                                    return item.stock_container_status_id==2;
                                }).length + '/' + params.value.length;
                            }
                        },
                        {headerName: 'Validity:', field: 'validity', valueFormatter: function (params) {
                                return utilities.isDateTimeValid(params.value) ? utilities.formatDateTime(params.value) : '';
                            }
                        }
                    ],
                },
                tooltipValueGetter: toolTipValueGetter,   //Uncomment for tooltip to work,
                cellClass: function(params){
                    return params.turn_in_reference ? 'has-tooltip' : '';
                }
            },
            {
                headerName: 'Inbound Transport Mode', field: 'ib_actual_visit_mode', 
                editable: function(params){
                    return true;
                },                
                width: 120,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilter: true,
                floatingFilterComponent: GridSelectFloatingFilter,
                //floatingFilterComponent: GridSetFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Inbound Carrier', field: 'inbound_carrier_id',
                editable: function(params){
                    return true;
                },                
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Inbound Transport ID', field: 'ib_actual_visit',
                editable: function(params){
                    return true;
                },                
                cellEditor: 'agTextCellEditor',
                //Filter
                filter: GridTextFilter,
                filterParams: { },
                //Floating
                floatingFilterComponent: GridTextFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Gate-Out Date', field: 'gate_out_date', hide: false,
                editable: function(params){
                    return params.data.stock_container_status_id>=2;
                },                
                width: 150,
                cellEditor: DatePicker,
                cellEditorParams: {
                    'format': 'datetime',
                    config: {
                        maxDate: dayJs().toDate()
                    }
                },
                comparator: utilities.dateComparator,
                valueFormatter: function (params) {
                    return params.value ? utilities.formatDateTime(params.value) : "";
                },
                //Filter
                filter: GridDateFilter,
                 filterParams: {format:'date'},
                //Floating
                //floatingFilterComponent: GridDateFloatingFilter,
                floatingFilterComponentParams: {}                
            },
            {
                headerName: 'Release Reference', field: 'booking_number', hide: false,
                editable: false,
                cellEditor: 'agTextCellEditor',
                tooltipComponentParams: {
                    type: 'text',
                    source: 'url',
                    sourceData: function (data) {
                        return data.booking_number ? "reference/"+data.release__reference.id +"/stockContainer" : null;
                    },
                    extractData: function(response){
                        return {
                            stockContainers: response.data.models.stockContainers,
                            validity: response.data.reference.validity
                        };
                    },
                    columns: [
                        {headerName: 'Containers:', field: 'stockContainers', valueFormatter: function (params) {
                                return params.value.filter(function (item) {
                                    return item.stock_container_status_id==5;
                                }).length + '/' + params.value.length;
                            }
                        },
                        {headerName: 'Validity:', field: 'validity', valueFormatter: function (params) {
                                return utilities.formatDateTime(params.value);
                            }
                        }
                    ]
                },
                tooltipValueGetter: toolTipValueGetter,
                cellClass: function(params){
                    return params.data.booking_number ? 'has-tooltip' : '';
                }
            },
            {
                headerName: 'Outbound Transport Mode', field: 'ob_actual_visit_mode', hide: false,
                width: 120,
                editable: function(params){
                    return params.data.stock_container_status_id>=2;
                },
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilter: true,
                floatingFilterComponent: GridSelectFloatingFilter,
                //floatingFilterComponent: GridSetFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Outbound Carrier', field: 'outbound_carrier_id',
                editable: function(params){
                    return params.data.stock_container_status_id>=2;
                },                
                //hide: props.reference.type==='return',
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Outbound Transport ID', field: 'ob_actual_visit', hide: false,
                editable: function(params){
                    return params.data.stock_container_status_id>=2;
                },                
                cellEditor: 'agTextCellEditor',
                //Filter
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                headerName: 'Container Condition', field: 'condition', 
                hide: false,
                width: 140,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Run State', field: 'run_state', hide: hideRunState,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: StockListValidator.isRequired('run_state') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilter: true,
                floatingFilterComponent: GridSelectFloatingFilter,
                //floatingFilterComponent: GridSetFloatingFilter,
                floatingFilterComponentParams: {},
            },
            {
                headerName: 'Repair Criteria', 
                field: 'repair_criteria',
                //Editor
                cellEditor: 'agTextCellEditor',
                //Filter
                filter: GridTextFilter,
                filterParams: { },
                //Floating
                floatingFilterComponent: GridTextFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Sale Grade', 
                field: 'sale_grade',
                //Editor
                cellEditor: 'agTextCellEditor',
                //Filter
                filter: GridTextFilter,
                filterParams: { },
                //Floating
                floatingFilterComponent: GridTextFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Cargo Condition', field: 'cargo_condition_id',
                //minWidth: 170,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: StockListValidator.isRequired('cargo_condition_id') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.code,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilter: true,
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Yard Position', field: 'position', hide: false,
                width: 120,
                cellEditor: 'agTextCellEditor',
                //Filter
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                headerName: 'Age', field: 'age', hide: false,
                width: 100,
                editable: false,
                valueFormatter: function (params) {
                    if (params.data.report__report) {
                        if (params.data.report__report.construction_year) {
                            //return diffrence in years and months bettween today and construction_year
                            let today = dayJs();
                            let moment = require('moment');
                            let construction_date = moment(params.data.report__report.construction_year, "MM-YYYY");
                            let construction_year = dayJs(construction_date, "MM-YYYY");
                            let years = today.diff(construction_year, 'year');
                            construction_year.add(years, 'years');
                            let months = today.diff(construction_year, 'months') - years * 12;
                            if (years || months) {
                                return years + 'y ' + months + 'm';
                            }
                            return '?';
                        }
                    } else {
                        return '';
                    }
                },
                //Filter
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            /*{
                headerName: 'Days in Depot', field: 'days_in', hide: false,
                editable: false,
                valueFormatter: function (params) {
                    if (params.data.gate_in_date) {
                        return dayJs().diff( dayJs(params.data.gate_in_date), 'days');
                    } else {
                        return "new";
                    }
                }
            },*/
            {
                headerName: 'Color', field: 'color', hide: false,
                width: 120,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                headerName: 'Floor', field: 'floor', hide: false,
                width: 120,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: '', field: 'temperature', hide: false,
                width: 100,
                cellEditor: 'agTextCellEditor',
                headerComponentParams: { template: '<span><i large class="mdi-thermometer mdi" style="font-size: 1.5rem"></em>' },
                tooltipComponentParams: {
                    type: 'simple'
                },
                headerTooltip: 'Temperature',
                //Filter
                filter: GridTextFilter,
                filterParams: {
                    pattern: '^\\d+$'
                },
                //Floating
                floatingFilterComponent: GridTextFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: '', field: 'humidity', hide: false,
                width: 100,
                cellEditor: 'agTextCellEditor',
                headerComponentParams: { template: '<span><i class="mdi-water-percent mdi" style="font-size: 1.5rem"></em></span>' },
                tooltipComponentParams: {
                    type: 'simple'
                },
                headerTooltip: 'Humidity',
                //Filter
                filter: GridTextFilter,
                filterParams: {
                    pattern: '^\\d+$'
                },
                //Floating
                floatingFilterComponent: GridTextFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: '', field: 'ventilation', hide: false,
                width: 100,
                cellEditor: 'agTextCellEditor',
                headerComponentParams: { template: '<span><i class="mdi-fan mdi" style="font-size: 1.5rem"-procent></em></span>' },
                tooltipComponentParams: {
                    type: 'simple'
                },
                headerTooltip: 'Ventilation',
                //Filter
                filter: GridTextFilter,
                filterParams: {
                    pattern: '^\\d+$'
                },
                //Floating
                floatingFilterComponent: GridTextFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Comment', field: 'comment', hide: false,
                cellEditor: 'agTextCellEditor',
                //Filter
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                headerName: 'Crun ID', field: 'crun_id',
                hide: true,
                editable: false,
                cellEditor: 'agTextCellEditor',

            },
            {
                headerName: 'Created', field: 'created_at', hide: false,
                width: 150,
                editable: false,
                cellEditor: 'agTextCellEditor',
                valueFormatter: function (params) {
                    return utilities.isDateTimeValid(params.value) ? utilities.formatDateTime(params.value) : '';
                },
                //Filter
                filter: GridDateFilter,
                 filterParams: {},
                //Floating
                //floatingFilterComponent: GridDateFloatingFilter,
                floatingFilterComponentParams: {}                
            },
            {
                headerName: 'Updated', field: 'updated_at', hide: false,
                flex: 1,
                minWidth: 150,
                resizable: false,
                suppressSizeToFit: true,
                editable: false,
                cellEditor: 'agTextCellEditor',
                valueFormatter: function (params) {
                    return utilities.isDateTimeValid(params.value) ? utilities.formatDateTime(params.value) : '';
                },
                //Filter
                filter: GridDateFilter,
                 filterParams: {},
                //Floating
                //floatingFilterComponent: GridDateFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'System', field: 'system', hide: true,
                cellEditor: 'agTextCellEditor',

            },
            {
                headerName: 'Line Operator', field: 'operator_name', hide: true,
                cellEditor: 'agTextCellEditor'
            },
            {
                headerName: 'Last OnHire Date', field: 'last_on_hire_date', hide: true,
                cellEditor: 'agTextCellEditor'
            },
            ]
        ];

    }

    const hideRunState = computed(() => {
        // get the user permissions
        const userPermissions = store.user.permissions;
        // check if the user has the permission to view the properties column
        const hasPermission = userPermissions.includes('System Root/Yamax/Stock List/Show Columns/Run State-Read');
        // return true if the user has the permission, false otherwise
        return !hasPermission;
    });

    const hideContainerStatus = computed(() => {
        // get the user permissions
        const userPermissions = store.user.permissions;
        // check if the user has the permission to view the properties column
        const hasPermission = userPermissions.includes('System Root/Yamax/Stock List/Show Columns/Container Status-Read');
        // return true if the user has the permission, false otherwise
        return !hasPermission;
    });

    return {
        getStockListDefinition
    };

}